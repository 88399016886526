<template>
  <div class="main-login-block center-block">
    <div>
        <div class="logo" v-html="Logotype"></div>
        <h3 class="title">Enter your credentials</h3>

        <div class="form-row">
          <div class="w-100">
            <input-text v-model="login"/>
            <div class="error">{{errorLogin}}</div>
          </div>
        </div>

        <div class="form-row">
          <div class="w-100">
            <input-password v-model="password"/>
            <div v-if="errorPassword" class="error">{{errorPassword}}</div>
          </div>
        </div>
        
        <div class="form-row" v-if="response">
          <div class="error server">
            {{response}}
          </div>
        </div>

        <div class="form-row">
          <div class="w-100">
            <router-link class="reset-link" to="/reset-password">Forgot your password?</router-link>
          </div>
          <div class="main-button" @click="onLogin">Log in</div>
        </div>

    </div>
  </div>
</template>

<script>
import { Logotype } from '@/utils/icons'
import InputText from '@/components/inputs/InputText'
import InputPassword from '@/components/inputs/InputPassword'
import { urls, fetchData } from '@/utils/urls.js' 

export default {
  name: 'Login',
  components: { InputText, InputPassword },
  
  data() {
    //test@test.com
    //password
    return {
        Logotype,
        login: {
            placeholder: 'Email address',
            value: null,
            isValid: true,
            clearable: false,
            required: true,
            readonly: false,
            regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            errorKey: ''
            //maxlength: 5,
            //minlength: 1
        },

        password: {
            placeholder: 'Password',
            value: null,
            isValid: true,
            clearable: false,
            required: true,
            readonly: false,
           // maxlength: 5,
           // minlength: 1
        },
        isDataLoaded: true,
        errorLogin: null,
        errorPassword: null,
        response: null
    }
  },

  computed: {
    isValidForm() {
      if(this.login.isValid && this.password.isValid) return true
      return false
    }
  },

  methods: {
    async fetchLogin(userData) {
      if(!userData  || !this.isDataLoaded) return

      this.isDataLoaded = false

      try {
        const response = await fetch(urls.authentication, {
          method: 'POST', 
          body: `username=${userData.username}&password=${userData.password}`,
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
            },
        })

        const json = await response.json()
        if(response.status === 200) {
          localStorage.setItem('userAuth', JSON.stringify(json))
          this.$store.commit('setToken', json)
          
          let result = await fetchData( urls.me, 'GET')
          if(result?.partner) {
            this.$router.push('/link-builder-partner/'+ result.partner) 
          }
          else if(result?.client)
          {
            this.$router.push('/client/'+ result.client)
          }
          else {
            this.$router.push('/link-builder-partners') 
          }
        } 
        else if (response.status > 399) {
          if(json?.detail) {
            this.response = json?.detail
          }
          localStorage.removeItem('userAuth')
          this.$store.commit('setToken', null)
        }
        this.isDataLoaded = true

      } catch (error) {
        this.isDataLoaded = true
        localStorage.removeItem('userAuth')
        this.$store.commit('setToken', null)
        console.log(error)
      }
  },

    onLogin() {
      this.showErrors()
      if(this.isValidForm) {
        this.fetchLogin({
          username: this.login.value,
          password: this.password.value,
        })
      }
    },

    showErrors() {
        if(!this.login.value) {
          if(this.login.errorKey === 'required') this.errorLogin = 'Please enter your email here'    
          else this.errorLogin = 'Please enter an email address'
        }
        else this.errorLogin = null

        if(!this.password.value) {
          this.errorPassword = 'Please enter a password'
        }
        else this.errorPassword = null
    }
  }
}
</script>
